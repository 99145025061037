<template>
  <div class="common-layout">
    <el-container>
      <el-row :gutter="20" style="width: 100%">
        <el-col :span="5">
          <el-card shadow="always">
            <template v-slot:header>
              <span class="card-header">账单类型</span>
            </template>
            <el-menu default-active="ELECTRIC" @select="handleMenuSelect">
              <!-- 使用文本链接代替 el-menu-item -->
              <el-menu-item index="ELECTRIC">
                <span class="menu-item-text" @click="handleMenuSelect('ELECTRIC')">峰谷平</span>
              </el-menu-item>
              <el-menu-item index="WATER">
                <span class="menu-item-text" @click="handleMenuSelect('WATER')">固定</span>
              </el-menu-item>
            </el-menu>
          </el-card>
        </el-col>
        <el-col :span="19">
          <!--          <el-button type="primary" size="small" @click="handleFileClick" style="margin-right: 10%;  margin-bottom: 1%;">导出账单</el-button>-->
          <el-button type="primary" size="small" @click="billGroupingSettings"
                     style="margin-left: 60%;  margin-bottom: 1%;">账单分组设置
          </el-button>
          <el-button type="primary" size="small" @click="open" style="margin-bottom: 1%;">账单日设置</el-button>
          <!-- 【账单日】编辑弹窗 -->
          <el-dialog
              title="设置"
              v-model="addDialogVisible"
              :destroy-on-close="true"
              draggable
              width="20%"
          >
            <el-form :style="{ display: 'flex', 'justify-content': 'center' }">
              <el-form-item>
                <el-input-number
                    v-model="dialogData"
                    :step="1"
                    style="margin-right: 10px;"
                />
                <span>日</span>
              </el-form-item>
            </el-form>
            <span class="currentBillingTime" @click="fetchBillingTime">当前{{
                currentBillingTime
              }} : {{ currentBillingTitle }} 日</span>

            <template v-slot:footer>
              <el-button @click="addDialogVisible = false">取 消</el-button>
              <el-button type="primary" @click="updateStrategyPeakValleyLevel">保存</el-button>
            </template>
          </el-dialog>

          <!--账单分组设置-->
          <el-dialog title="账单分组设置" v-model="isBillGroupingSettings" class="batch-add-dialog">
            <div class="transfer-container">

              <div class="equipment-transfer-container">
                <el-transfer
                    v-model:modelValue="selectedCustomRegionIds"
                    :data="customRegionData"
                    :titles="['分组列表', '已绑分组']"
                    filterable
                    filter-placeholder="请输入分组名称"
                    class="equipment-transfer"
                />
              </div>
            </div>
            <template #footer>
              <el-button @click="closeBatchAddCustomRegionDialog">取消</el-button>
              <el-button type="primary" @click="batchAddCustomRegions">确定</el-button>
            </template>
          </el-dialog>

          <el-card shadow="always">
            <!-- 表格 -->
            <el-table
                :data="currentTableData"
                style="width: 100%"
                @row-click="handleRowClick"
                show-selection
            >
              <el-table-column prop="name" label="账单名称" align="left" sortable/>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button type="text" size="small" @click="exportToExcel(scope.row)">下载</el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-card>
          <!--          </el-main>-->
        </el-col>
      </el-row>
    </el-container>
  </div>
</template>

<script setup>
import {ref,onMounted } from "vue";
import apiModule from "@/APIConfig";
import axios from "axios";

// 【页面加载时触发查询】
onMounted(() => {
  queryCustomRegionPeakValleyLevelDay();//峰谷平查询
});


// 【表格数据】用于存储选中的表格行
const selectedData = ref([]);
//【表格数据】
const currentTableData = ref([])
// 【表格数据】表格选中项变化时触发
const handleRowClick = (rows) => {
  selectedData.value = rows
};

// 【账单类型选择】
const handleMenuSelect = (menuIndex) => {
  console.log('Selected menu index:', menuIndex);
  if (menuIndex == null) {
    menuIndex === 'ELECTRIC'
  }
  // 根据选中的菜单项执行不同的操作
  if (menuIndex === 'ELECTRIC') {
    queryCustomRegionPeakValleyLevelDay();
  } else if (menuIndex === 'WATER') {
    queryCustomgetAllFixed();  // 执行固定查询
  }
};


//【查询峰谷平账单】
const queryCustomRegionPeakValleyLevelDay = async () => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllPeakValleyLevel}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 如果 API 返回的数据是正常的，设置 currentTableData
    if (response.data.code === 0 && response.data.data) {
      currentTableData.value = response.data.data; // 把返回的文件数据存入响应式对象
    } else {
      console.error('API returned an error or empty data');
    }

    return response.data;

  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};

//【查询固定账单】
const queryCustomgetAllFixed = async () => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAllFixed}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 如果 API 返回的数据是正常的，设置 currentTableData
    if (response.data.code === 0 && response.data.data) {
      currentTableData.value = response.data.data; // 把返回的文件数据存入响应式对象
    } else {
      console.error('API returned an error or empty data');
    }

    return response.data;

  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};

//【下载账单1】
const exportToExcel = async (row) => {
  await downloadBill(row.name, row.urlPath);
};

//【下载账单2】
const downloadBill = async (name, urlPath) => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.download}`, {
      params: {urlPath: urlPath},  //
      responseType: 'arraybuffer', //确保返回的是二进制数据
      headers: {
        'Content-Type': 'application/json',
      },
    });
    console.log(response)
    const blob = new Blob([response.data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',  // 正确的 MIME 类型用于 .xlsx 文件
      // type: 'application/vnd.ms-excel',  // 正确的 MIME 类型用于 .xlsx 文件
    });

    const downloadUrl = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.setAttribute("download", name);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(downloadUrl);

  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};


//【账单日设置】编辑弹窗
const addDialogVisible = ref(false)
//【账单日设置】弹窗数据默认显示1
const dialogData = ref(1);

// 【账单日设置】打开弹窗
const open = () => {
  addDialogVisible.value = true;  // 设置为 true，弹窗就会显示
  fetchBillingTime();//查询账单日 打开就调用
};


// 【账单日设置弹窗】保存按钮触发
const updateStrategyPeakValleyLevel = () => {
  const day = dialogData.value; // 获取弹窗中输入的值
  if (day < 1 || day > 28) {
    ElMessage({
      type: 'error',
      message: '请输入1到28之间的数字！'
    });
    return; // 如果值不合法，阻止后续操作
  }
  fetchData(day); // 输入合法时，调用fetchData函数，传递day参数
  // 关闭弹窗
  addDialogVisible.value = false;
};
//【账单日设置】
import {ElMessage} from 'element-plus';

const fetchData = async (day) => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.updateBillDictionary}`, {
      params: {day: day}  // 这里会转化为 ?day=5
    });

    // 如果请求成功且返回的数据状态是 0
    if (response.data.code === 0) {
      console.log('数据获取成功:', response.data);

      // 使用 ElMessage 显示成功提示
      ElMessage({
        type: 'success',
        message: response.data.message || '设置成功' // 如果返回有message字段，显示它；否则显示默认提示
      });
    } else {
      // 请求失败时，显示失败消息
      console.error('请求失败:', response.data.message);
      ElMessage({
        type: 'error',
        message: response.data.message || '设置失败'
      });
    }
  } catch (error) {
    console.error('请求失败:', error);

    // 发生错误时显示错误提示
    ElMessage({
      type: 'error',
      message: '请求发生错误，请稍后重试'
    });
  }
};

//【查询账单日】调用fetchBillingTime
const currentBillingTime = ref(''); // 名称
const currentBillingTitle = ref(''); // 几号

// 【查询账单日】查询
const fetchBillingTime = async () => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getBillDictionary}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 如果 API 返回的数据是正常的，设置 currentTableData 和 jQuery 更新
    if (response.data.code === 0 && response.data.data) {
      currentBillingTime.value = response.data.data.name
      currentBillingTitle.value = response.data.data.title
    } else {
      console.error('API returned an error or empty data');
    }
    return response.data;
  } catch (err) {
    console.error("Error fetching data:", err);
    throw err;
  }
};


//【账单分组设置】
const isBillGroupingSettings = ref(false);

// 【账单分组设置】打开弹框的函数
const billGroupingSettings = () => {
  isBillGroupingSettings.value = true; // 设置为 true 打开弹框
  queryAllGroups();
  queryCustomRegionId();
};

// 【账单分组设置】取消弹框的函数
const closeBatchAddCustomRegionDialog = () => {
  isBillGroupingSettings.value = false; // 设置为 false 关闭弹框
};

// 【账单分组设置】全部分组数据，
const customRegionData = ref([]);
// 【账单分组设置】已绑定的分组
const selectedCustomRegionIds = ref([]);


//【查询全部分组】
const queryAllGroups = async () => {
  try {
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.getAlllocation}`, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 如果 API 返回的数据是正常的，设置 customRegionData
    if (response.data.code === 0 && response.data.data) {
      // 格式化数据以适应 el-transfer 的数据结构
      customRegionData.value = response.data.data.map(group => ({
        key: group.id,  // 假设 API 返回的数据每个组有一个 `id` 和 `name`
        label: group.name // 这里的 `name` 是分组名称
      }));
    } else {
      console.error('API returned an error or empty data');
    }
  } catch (err) {
    console.error("Error fetching group data:", err);
  }
};

// 【绑定分组弹框】【确定】
const batchAddCustomRegions = async () => {
  // 获取用户选中的分组ID
  const selectedRegionIds = selectedCustomRegionIds.value;

  // 如果有选中的分组ID
  if (selectedRegionIds.length > 0) {
    // 调用设置生成账单的分组接口，传递选中的分组ID
    queryInsertBillConfig(selectedRegionIds);
  } else {
    console.error("没有选择任何分组");
  }
  // 关闭弹框
  isBillGroupingSettings.value = false;
}


// 【查询绑定的分组id集合】
const queryCustomRegionId = async (params) => {
  try {
    // 向 API 发送请求，传递参数
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryCustomRegionIds}`, params, {
      headers: {
        'Content-Type': 'application/json',
      },
    });

    // 如果 API 返回的数据是正常的，设置 customRegionData
    if (response.data.code === 0 && response.data.data) {
      // 将返回的 ID 列表转换为 el-transfer 需要的格式
      selectedCustomRegionIds.value = response.data.data;
    } else {
      console.error('API returned an error or empty data');
    }
  } catch (err) {
    console.error("Error fetching group data:", err);
  }
};

// 【账单分组设置【点击确定】
const queryInsertBillConfig = async (selectedRegionIds) => {
  try {
    const response = await axios.post(
        `${apiModule.apiBaseUrl}${apiModule.endpoints.insertBillConfig}`,
        {custom_region_id: selectedRegionIds},
        {headers: {'Content-Type': 'application/json'}}
    );

    // 根据 code 的值判断操作是否成功
    if (response.data.code === 0) {
      // 如果返回的 code 是 0，表示成功，显示成功提示
      ElMessage({
        message: response.data.message,  // 显示接口返回的 message
        type: 'success',
      });
    } else if (response.data.code === 1) {
      // 如果返回的 code 是 1，表示失败，显示失败提示
      ElMessage({
        message: `操作失败: ${response.data.message}`,
        type: 'error',
      });
    } else {
      // 其他 code 的处理（如果有其他状态码）
      ElMessage({
        message: `未知错误: ${response.data.message}`,
        type: 'error',
      });
    }
  } catch (err) {
    console.error("Error setting bill group:", err);
    // 显示网络错误的提示消息
    ElMessage({
      message: "网络错误，请稍后重试",
      type: 'error',
    });
  }
};

</script>
<style scoped>
</style>
