        /*
                           _ooOoo_
                          o8888888o
                          88" . "88
                          (| -_- |)
                          O\  =  /O
                       ____/`---'\____
                     .'  \\|     |//  `.
                    /  \\|||  :  |||//  \
                   /  _||||| -:- |||||-  \
                   |   | \\\  -  /// |   |
                   | \_|  ''\---/''  |   |
                   \  .-\__  `-`  ___/-. /
                 ___`. .'  /--.--\  `. . __
              ."" '<  `.___\_<|>_/___.'  >'"".
             | | :  `- \`.;`\ _ /`;.`/ - ` : | |
             \  \ `-.   \_ __\ /__ _/   .-` /  /
        ======`-.____`-.___\_____/___.-`____.-'======
                           `=---='
        ^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^^
                 佛祖保佑       永无BUG
        */
import {createRouter, createWebHashHistory} from 'vue-router';
import OverviewView from '../components/Overview/OverviewView.vue';
import EnergyReport from '../components/EnergyReport/EnergyReport.vue';
import DepartmentManagement from '../components/DepartmentManagement.vue';
import EnergyPricingStrategy from '../components/EnergyPricingStrategy.vue';
import EnergyUsageReport from '../components/EnergyUsageReport.vue';
import DeviceManagement from '../components/DeviceManagement/DeviceManagement.vue';
import ChartAnalysis from '../components/ChartAnalysis/ChartAnalysis.vue'
import EnergyComparison from '../components/EnergyComparison/EnergyComparison.vue';
import TimeSharing from '../components/TimeSharing.vue';
import OriginalData from '../components/OriginalData.vue';
import UtilitybView from '../components/UtilitybView.vue'
import FixedQuery from '../components/FixedQuery.vue'
import BillingData from "@/components/BillingData.vue";
        import AutomaticMeterReading from "@/components/AutomaticMeterReading.vue";



const routes = [
  {
    path: '/DeviceManagement',
    name: 'DeviceManagement', 
    component: DeviceManagement
  },
  {
    path: '/overview',
    name: 'OverviewView', 
    component: OverviewView  
  },
  {
    path: '/EnergyReport',
    name: 'EnergyReport', 
    component: EnergyReport  
  },
  {
    path: '/department-management',
    name: 'DepartmentManagement',
    component: DepartmentManagement
  },
  {
    path: '/EnergyPricingStrategy',
    name: 'EnergyPricingStrategy',
    component: EnergyPricingStrategy
  },
  {
    path: '/EnergyUsageReport',
    name: 'EnergyUsageReport',
    component: EnergyUsageReport
  },
  {
    path: '/ChartAnalysis',
    name: 'ChartAnalysis',
    component: ChartAnalysis
  },
  {
    path: '/EnergyComparison',
    name: 'EnergyComparison',
    component: EnergyComparison
  },
  {
    path:'/TimeSharing',
    name:'TimeSharing',
    component:TimeSharing
  },
  {
    path:'/OriginalData',
    name:'OriginalData',
    component:OriginalData
  },{
    path:'/FixedQuery',
    name:'FixedQuery',
    component:FixedQuery
  },
  {
    path:'/UtilitybView',
    name:'UtilitybView',
    component:UtilitybView
  },

  //独立页面
  {
    path: '/DeviceManagement.html',
    name: 'DeviceManagement.html',
    component: DeviceManagement
  },
  {
    path: '/overview.html',
    name: 'OverviewView.html',
    component: OverviewView
  },
  {
    path: '/EnergyReport.html',
    name: 'EnergyReport.html',
    component: EnergyReport
  },
  {
    path: '/department-management.html',
    name: 'DepartmentManagement.html',
    component: DepartmentManagement
  },
  {
    path: '/EnergyPricingStrategy.html',
    name: 'EnergyPricingStrategy.html',
    component: EnergyPricingStrategy
  },
  {
    path: '/EnergyUsageReport.html',
    name: 'EnergyUsageReport.html',
    component: EnergyUsageReport
  },
  {
    path: '/ChartAnalysis.html',
    name: 'ChartAnalysis.html',
    component: ChartAnalysis
  },
  {
    path: '/EnergyComparison.html',
    name: 'EnergyComparison.html',
    component: EnergyComparison
  },
  {
    path:'/TimeSharing.html',
    name:'TimeSharing.html',
    component:TimeSharing
  },
  {
    path:'/OriginalData.html',
    name:'OriginalData.html',
    component:OriginalData
  },{
    path:'/FixedQuery.html',
    name:'FixedQuery.html',
    component:FixedQuery
  },
  {
    path:'/UtilitybView.html',
    name:'UtilitybView.html',
    component:UtilitybView
  },
  {
    path:'/BillingData.html',
    name:'BillingData.html',
    component:BillingData
  },
  {
    path:'/AutomaticMeterReading.html',
    name:'AutomaticMeterReading.html',
    component:AutomaticMeterReading
  }
];

const router = createRouter({
  // history: createWebHistory(),
  history: createWebHashHistory(process.env.BASE_URL),
  routes
});

export default router;
