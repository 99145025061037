<template>
  <div class="common-layout">
    <el-container>
      <el-main>
        <el-card shadow="always">
          <div class="query-container">
            <el-input v-model="input" style="width: 200px" placeholder="请输入设备名" clearable/>
            <!-- 时间选择-->
            <el-date-picker
                v-model="value"
                type="daterange"
                range-separator="到"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                :default-time="defaultTime"
                size="medium"
                style="width: 280px; margin-left: 20px;"
            />
            <el-button type="primary" :icon="Search" @click="queryCustomRegionPeakValleyLevelDay"
                       style="margin-left: 20px;">查询
            </el-button>
            <!-- 批量导入按钮 -->
            <el-button type="primary" @click="handleFileClick" style="margin-left: 15px; width: 80px;">批量导入
            </el-button>
            <input type="file" ref="fileInput" style="display: none" @change="insertBatchXLSX" accept=".xlsx, .xls"/>
            <!-- 隐藏文件上传输入框，用来选择 xlsx 文件 -->

            <el-button type="primary" @click="exportTemplateXLSX" style="margin-left: 10px; width: 80px;">导出模板
            </el-button>
            <el-button type="primary" @click="batchDelete" style="margin-left: 10px; width: 80px;">批量删除</el-button>
          </div>

          <!--导出-->
          <el-dialog
              title=""
              v-model="importExcel"
              width="50%"
              :style="{ 'max-width': '600px', 'min-width': '200px' }"
          >
            <template v-slot:header>
              <div style="display: flex; align-items: center; justify-content: flex-start; width: 100%;">
                <span>导出</span>
              </div>
            </template>

            <div
                style="display: flex; flex-direction: column; align-items: flex-start; gap: 20px; padding: 20px; overflow: auto;">
              <!-- 选择分组 -->
              <el-form-item label="选择分组" label-width="100px" style="width: 100%; max-width: 500px;">
                <el-tree-select
                    v-model="form.parentId"
                    :data="treeData"
                    placeholder="请选择分组"
                    default-expand-all
                    multiple
                    clearable
                    show-checkbox
                    auto-expand-parent
                    value-key="id"
                    label-key="name"
                    :props="{label: 'name', children: 'children' }"
                    style="width: 100%;"
                />
              </el-form-item>

              <!-- 模版选择 -->
              <el-form-item label="模版:" label-width="80px" style="width: 100%; max-width: 500px;">
                <el-radio-group v-model="form.resource">
                  <el-radio value="普通模版">普通模版</el-radio>
                  <el-radio value="日逐时模板">日逐时模板</el-radio>
                </el-radio-group>
              </el-form-item>
            </div>

            <template v-slot:footer>
              <el-button @click="closeDialog">取 消</el-button>
              <el-button type="primary" @click="confirmConfig">确 定</el-button>
            </template>
          </el-dialog>


          <!--批量删除-->
          <el-dialog
              title=""
              v-model="deleteDialogVisible"
              width="26%"
          >
            <!-- 自定义标题部分 -->
            <template #header>
              <div style="display: flex; align-items: center; justify-content: flex-start; width: 100%;">
                <span>删除</span>
              </div>
            </template>

            <!-- 对话框内容 -->
            <div style="display: flex; align-items: center;">
              <el-icon style="color: orange; font-size: 20px; margin-right: 5px;">
                <QuestionFilled/>
              </el-icon>
              <span>此操作将永久删除选中的数据，是否继续？</span>
            </div>

            <!-- 底部按钮 -->
            <template v-slot:footer>
              <el-button @click="deleteDialogVisible = false">取 消</el-button>
              <el-button
                  type="primary"
                  :loading="isDeleting"
                  @click="deleteRecordsConfig"
              >
                确 定
              </el-button>

            </template>
          </el-dialog>
          <!-- 表格 -->
          <el-table
              :data="currentTableData"
              style="width: 100%"
              @row-click="handleRowClick"
              @selection-change="handleSelectionChange"
              show-selection
          >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="id" label="id" align="center" width="100px" sortable/>
            <el-table-column prop="equipmentName" label="设备名" align="center" sortable/>
            <el-table-column prop="variableId" label="点位id" align="center" sortable/>
            <el-table-column prop="value" label="值" align="center" sortable/>
            <el-table-column prop="deleted" label="是否删除" align="center" width="100px" sortable
                             :formatter="deletedFormatter"/>
            <el-table-column prop="readTime" label="抄表时间" align="center" sortable/>
          </el-table>
          <!-- 分页组件放右边 -->
          <div class="pagination-container">
            <!-- 分页组件 -->
            <el-pagination
                class="pagination"
                :current-page="currentPage"
                :page-size="pageSize"
                :page-sizes="[10, 20, 30, 50, 100]"
                :total="totalRecords"
                layout="total, prev, pager, next, jumper, sizes"
                @current-change="handlePageChange"
                @size-change="handlePageSizeChange"
            />
          </div>
        </el-card>
      </el-main>
    </el-container>
  </div>
</template>

<script setup>
import {ref, onMounted} from "vue";
import apiModule from "@/APIConfig";
import axios from "axios";
import ApiConfig from "@/APIConfig";
import {ElMessage} from "element-plus";
import {Search} from "@element-plus/icons-vue";


// 【页面加载时触发查询】
onMounted(() => {
  queryCustomRegionPeakValleyLevelDay();//查询记录表
});
//【请输入设备名】
const input = ref('')

//【时间选择】
const value = ref('')
const defaultTime = ref < [Date, Date] > ([
  new Date(2000, 1, 1, 0, 0, 0),
  new Date(2000, 2, 1, 23, 59, 59),
])


//【表格】
const selectedData = ref([]);// 用于存储选中的表格行
const currentTableData = ref([])//表格数据
const handleRowClick = (rows) => {// 表格选中项变化时触发
  selectedData.value = rows
};
const deletedFormatter = (row, column, cellValue) => { // 判断 deleted 字段的值，返回中文 "是" 或 "否"
  return cellValue === 1 ? '是' : '否';
};


//【分页】
const currentPage = ref(1);
const pageSize = ref(10); // 每页10条数据
const totalRecords = ref(0); // 总记录数
const handlePageChange = (page) => {// 处理每页条数变化
  currentPage.value = page;
  queryCustomRegionPeakValleyLevelDay(); // 每次分页改变时重新加载数据
};
const handlePageSizeChange = (size) => {// 处理每页条数变化
  pageSize.value = size;
  queryCustomRegionPeakValleyLevelDay(); // 每次页大小改变时重新加载数据
};


//【查询记录表】
const queryCustomRegionPeakValleyLevelDay = async () => {
  try {
    const equipmentName = input.value || defaultTime.value; // 假设你用 `input` 变量绑定了设备名  // 获取设备名
    const [startTime, endTime] = value.value && value.value.length > 0 ? value.value : [null, null]; // 获取时间范围
    const formattedStartTime = startTime ? formatDate(startTime) : null;// 格式化时间，假设使用的是 moment 或类似的库，如果没有可以直接使用 Date 对象
    const formattedEndTime = endTime ? formatDate(endTime) : null;
    console.log(formattedStartTime + "-------------" + formattedEndTime)
    // 请求参数
    const params = {
      page: currentPage.value,  // 当前页码
      limit: pageSize.value,    // 每页显示条数
      equipmentName,            // 设备名
      startTime: formattedStartTime,  // 开始时间
      endTime: formattedEndTime     // 结束时间
    };
    // 发送请求到后端
    const response = await axios.get(`${apiModule.apiBaseUrl}${apiModule.endpoints.queryRecords}`, {
      params,
      headers: {
        'Content-Type': 'application/json',
      },
    });
    if (response.data.code === 0 && response.data.data) {    // 处理返回的数据
      currentTableData.value = response.data.data.records;  // 获取分页后的数据
      totalRecords.value = response.data.data.total || 0;   // 获取总记录数
    } else {
      console.error('API returned an error or empty data');
    }
    return response.data;
  } catch (err) {
    console.error("Error fetching peak valley level data:", err);
    throw err;
  }
};
const formatDate = (date) => {//【查询记录表】开始时间-结束时间 时间转换
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // 月份从 0 开始，+1
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
};


//【批量删除】编辑弹窗
const isDeleting = ref(false)//是否加载【图标】
const deleteDialogVisible = ref(false)//删除【窗口】
const selectedRows = ref([]);// 选中的行数据
const handleSelectionChange = (val) => {// 处理选中行变化
  selectedRows.value = val;
};
const batchDelete = () => {// 【批量删除】打开弹窗
  // 检查是否有选中的行
  if (selectedRows.value.length === 0) {
    ElMessage({
      message: `没有选中的行！`,
      type: 'error',
    });
    return;
  }
  deleteDialogVisible.value = true;  // 设置为 true，弹窗就会显示
};
//【批量删除】接口
const deleteRecordsConfig = async () => {
  isDeleting.value = true;  // 开始加载
  try {
    // 提取选中行的 ID
    const selectedIds = selectedRows.value.map(row => row.id);
    console.log(selectedIds);
    // 发起删除请求
    const response = await axios.post(
        `${apiModule.apiBaseUrl}${apiModule.endpoints.deleteRecords}`,
        {ids: selectedIds},
        {headers: {'Content-Type': 'application/json'}}
    );
    // 根据返回的 code 判断操作结果
    if (response.data.code === 0) {
      ElMessage({ // 操作成功
        message: response.data.message,
        type: 'success',
      });
    } else if (response.data.code === 1) {
      ElMessage({ // 操作失败
        message: `操作失败: ${response.data.message}`,
        type: 'error',
      });
    } else {
      ElMessage({ // 其他错误处理
        message: `未知错误: ${response.data.message}`,
        type: 'error',
      });
    }
    queryCustomRegionPeakValleyLevelDay();    // 重新查询数据
  } catch (err) {
    console.error("Error deleting records:", err);    // 捕获异常并显示错误提示
    ElMessage({
      message: "网络错误，请稍后重试",
      type: 'error',
    });
    queryCustomRegionPeakValleyLevelDay();   // 重新查询数据，确保操作不会卡死
  } finally {
    isDeleting.value = false;  // 请求结束，隐藏加载动画
    deleteDialogVisible.value = false;  // 关闭对话框
  }
};


//【导出模版】
const importExcel = ref(false); //【弹窗】
const form = ref({resource: '普通模版', parentId: null,});// 默认选择 "普通模版"
const treeData = ref([]);
const closeDialog = () => {
  importExcel.value = false;
};// 【关闭导出】关闭弹窗
const exportTemplateXLSX = () => {// 【打开导出】打开弹窗
  importExcel.value = true;
  queryAllGroups();//查询全部分组
}
//【导出模版弹窗】接口  选择分组 【查询全部分组】
const queryAllGroups = async () => {
  if (treeData.value.length === 0) {
    // 如果树形数据为空，则先加载数据
    try {
      const response = await axios.get(
          `${apiModule.apiBaseUrl}${apiModule.endpoints.getAlllocation}`,
          {headers: {'Content-Type': 'application/json'}}
      );

      if (response.data.code === 0 && response.data.data) {
        // 构建树形结构
        const map = new Map();
        const rawData = response.data.data;
        rawData.forEach((item) => map.set(item.id, {...item, children: []}));
        rawData.forEach((item) => {
          if (item.parent_id === null) {
            // 根节点直接推入treeData
            treeData.value.push(map.get(item.id));
          } else if (map.has(item.parent_id)) {
            // 将子节点添加到对应父节点的children数组中
            map.get(item.parent_id).children.push(map.get(item.id));
          }
        });
        console.log(rawData);
      } else {
        console.error("分组数据加载失败或为空");
        return;
      }
    } catch (error) {
      console.error("获取分组数据失败:", error);
      return;
    }
  }
};

//【导出模版】选择分组
const confirmConfig = () => {
  if (!form.value.parentId || form.value.parentId.length === 0) {
    // 提示用户选择至少一个分组
    ElMessage.warning("请选择一个分组或多个分组！！");
    return;
  }
  downloadExcelFile();
};

//【导出模版】弹窗点击【确定
const downloadExcelFile = async () => {
  try {
    // 请求后端接口
    const response = await axios.post(
        `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.allExcel111}`, // 请求的接口地址
        {
          customRegionId: form.value.parentId,
          template: form.value.resource,
        },  // 发送的请求数据
        {
          headers: {'Content-Type': 'application/json'},  // 设置请求头为 JSON 格式
          responseType: 'arraybuffer'  // 设置返回的数据类型为二进制数据
        }
    );

    // 检查文件大小是否为 0
    if (response.data.byteLength === 0) {
      ElMessage.error("文件为空，下载失败");
      return;
    }
    // 尝试将 ArrayBuffer 转换为字符串
    let jsonResponse = null;
    let text = '';

    try {
      text = new TextDecoder().decode(response.data); // 解码为字符串
      jsonResponse = JSON.parse(text);  // 尝试解析为 JSON
    } catch (e) {
      // 如果解析失败，说明不是有效的 JSON 数据，继续处理二进制文件下载
      console.log("响应数据不是 JSON 格式，跳过 JSON 解析");
    }

    // 如果成功解析出 JSON 且包含 message 字段
    if (jsonResponse && jsonResponse.message === "没有手动抄表的设备!") {
      ElMessage.error("没有手动抄表的设备，请先去【设备管理】把抄表类型改成手动！！");
      return;
    }

    if (response.status === 200) {
      console.log("File data:", response.data);
      // 创建 Blob 对象
      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', // 指定文件类型
      });
      // 创建下载链接
      const downloadUrl = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = downloadUrl;
      a.download = '手动抄表' + form.value.resource + '.xlsx'; // 设置默认文件名，可以根据需要动态生成
      document.body.appendChild(a);  // 将 <a> 标签加入 DOM
      a.click();  // 触发下载
      document.body.removeChild(a);  // 下载完成后移除 <a> 标签
      window.URL.revokeObjectURL(downloadUrl);  // 释放 URL 对象
    }
    form.value.parentId = null;  // 清空分组选项
    importExcel.value = false;

  } catch (err) {
    console.error('下载失败:', err);
    ElMessage.error('下载文件失败' + err);
    importExcel.value = false;
  }
}


//【导入】
const fileInput = ref(null);
const loading = ref(false); // 添加这个来跟踪加载状态
const handleFileClick = () => {//打开弹窗 批量导入
  fileInput.value.click(); // 触发文件输入框点击事件
};
//【导入】批量插入
const insertBatchXLSX = async (event) => {
  const file = event.target.files[0];
  if (!file) return;
  try {
    const formData = new FormData();
    formData.append('file', file);

    loading.value = true; // 设置加载状态为 true

    const response = await axios.post(
        `${ApiConfig.apiBaseUrl}${ApiConfig.endpoints.depositManualAndInfluxdb}`,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        }
    );
    const message = response.data.message
    if (message === "操作成功") {
      ElMessage.success(response.data.message);
    } else {
      ElMessage.error(response.data.message);
    }

  } catch (error) {
    ElMessage.error('导入区域数据失败');
    console.error(error);
  } finally {
    loading.value = false; // 重置加载状态为 false
    await queryCustomRegionPeakValleyLevelDay();
    event.target.value = ''; // 重置文件输入框
  }
};

</script>

<style scoped>
.demo-tabs > .el-tabs__content {
  padding: 32px;
  font-size: 32px;
  font-weight: 600;
}

.query-container {
  text-align: left;
  margin-bottom: 20px;
}

/*【分页】*/
.pagination {
  float: right; /* 将分页组件浮动到右边 */
  margin-top: 20px; /* 可以根据需要调整分页组件与表格之间的距离 */
}

.pagination-container {
  display: flex;
  justify-content: flex-end; /* 可改为 flex-start 或 flex-end 调整位置 */
}

</style>
